import Card from '@/components/Card';
import type { NextModules } from '@/model/types';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { N0, Y300 } from '@/styles/color';
import AppSection from './components/AppSection';
import MenuSection from './components/MenuSection';
import { homeCx } from './style';
import WidgetCarousel from '../components/Widget/Carousel';
import WidgetMenu from '../components/Widget/Menu';
import WidgetCard from '../components/Widget/Card';
import useGetHome from '../hooks/use-get-home';
import Partnership from './components/Partnership/Partnership';
import Footer from '../components/Footer';

const WIDGET_TYPE = {
  CAROUSEL: 'carousel',
  MENU: 'menu',
  CARD: 'card',
};

/**
 * @function Home
 */
const Home: NextModules = () => {
  // const homeData = MOCK_HOME_DATA || [];
  const { data } = useGetHome();
  // const homeData = MOCK_HOME_DATA || [];
  const homeData = data || [];

  return (
    <>
      <div
        style={{
          // backgroundImage: `url(${bg_image})`,
          height: 800,
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'top',
          backgroundColor: 'rgba(0,0,0,0.5)',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h1"
          style={{ display: 'flex', justifyContent: 'center', paddingTop: 280, marginBottom: 16, color: N0 }}
        >
          Temukan properti di{' '}
          <div style={{ color: Y300, marginLeft: 8 }}>
            Jakarta Barat <KeyboardArrowDownRoundedIcon fontSize="large" />
          </div>
        </Typography>
        <Typography variant="body1" style={{ color: N0 }}>
          Dapatkan pilihan terbaik rumah dijual di Indonesia harga terjangkau bisa kpr cicilan ringan proses mudah dan
          cepat.
        </Typography>
      </div>
      <div className={homeCx}>
        <Card style={{ backgroundColor: N0, padding: 0, maxWidth: 1176, margin: '-80px auto 48px auto' }}>
          <MenuSection />
        </Card>
        <div style={{ maxWidth: 1176, margin: 'auto' }}>
          {homeData.map((home: any) => {
            const widgetType = home.type;
            //TODO: fix this any
            const widgetData = home.data as any;

            switch (widgetType) {
              case WIDGET_TYPE.CAROUSEL:
                return (
                  <WidgetCarousel
                    key={widgetData.title}
                    title={widgetData.title}
                    description={widgetData?.subtitle || ''}
                    data={widgetData?.card || ''}
                    filter={widgetData?.filter || []}
                  />
                );
              case WIDGET_TYPE.MENU:
                return <WidgetMenu key={widgetData.title} title={widgetData.title} data={widgetData?.menu || []} />;
              default:
                return (
                  <WidgetCard
                    key={widgetData.title}
                    title={widgetData?.title || ''}
                    description={widgetData?.subtitle || ''}
                    data={widgetData?.card || []}
                  />
                );
            }
          })}
        </div>
        <AppSection />
        <Partnership />
        <Footer />
      </div>
    </>
  );
};

export default Home;
