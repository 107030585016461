import Image from 'next/image';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import useGetMenu from '@/routes/Home/hooks/use-get-menu';
import { buttonContainerCx, buttonCx, menuContainerCx } from './style';

interface MenuButtonProps {
  image: string;
  text: string;
  onClick: () => void;
}

const MenuButton = ({ image, text, onClick }: MenuButtonProps) => {
  return (
    <div className={buttonContainerCx} onClick={onClick}>
      <div className={buttonCx}>
        <Image src={image} alt={text} width={40} height={40} unoptimized />
      </div>
      <Typography variant="body1">{text}</Typography>
    </div>
  );
};

const MenuSection = () => {
  const router = useRouter();
  // const { data, loading } = useGetMenu();
  const { data } = useGetMenu();

  const menuList = data?.data?.menu || [];
  return (
    <>
      <div className={menuContainerCx}>
        {/* TODO: fix this any */}
        {menuList.map((menu: any) => (
          <MenuButton key={menu.name} image={menu.icon} text={menu.name} onClick={() => router.push(menu.web_url)} />
        ))}
      </div>
    </>
  );
};

export default MenuSection;
