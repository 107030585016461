import { css } from '@emotion/css';

export const badgeContainerCx = css`
  display: flex;
  column-gap: 12px;
`;

export const informationContainerCx = css`
  display: flex;
  flex-direction: column;
  min-width: 530px;
  margin: 56px;
  flex: 1;
`;

export const screenContainerCx = css`
  display: flex;
  position: relative;
  flex: 1;

  & .img-app-onboarding {
    position: absolute;
    margin: 0;
  }
`;
