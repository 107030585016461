import Typography from '@mui/material/Typography';
import Card from '@/components/Card';
import Image from 'next/image';
import { badgeContainerCx, informationContainerCx, screenContainerCx } from './style';

const AppSection = () => {
  return (
    <Card
      style={{
        backgroundColor: '#F2FDFD',
        padding: 0,
        maxWidth: 1176,
        margin: 'auto',
      }}
    >
      <div className={informationContainerCx}>
        <Typography variant="h1">Dapatkan promo menarik hanya di BestHome App.</Typography>
        <Typography variant="body1" style={{ marginBottom: 48 }}>
          Download the app to manage your projects, keep track of the progress and complete tasks without
          procastinating. Stay on track and complete on time!
        </Typography>
        <div className={badgeContainerCx}>
          <Image alt="download-on-google-play" src="/logo/badge/logo-google-play.svg" width={232} height={40} />
          <Image alt="download-on-app-store" src="/logo/badge/logo-app-store.svg" width={232} height={40} />
        </div>
      </div>
      <div className={screenContainerCx}>
        <Image
          className="img-app-onboarding"
          alt="app-screen-onboarding"
          src="/image/image-app-example.svg"
          width={500}
          height={500}
        />
      </div>
    </Card>
  );
};

export default AppSection;
