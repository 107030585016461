import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { partnershipContainerCx } from './style';

const Partnership = () => {
  return (
    <div className={partnershipContainerCx} style={{ maxWidth: 1176, margin: 'auto' }}>
      <div className="partnership__info">
        <Typography variant="h4" style={{ marginBottom: 8 }}>
          Kerja Sama dan Kemitraan
        </Typography>
        <Typography variant="body1">
          bekerja sama dengan mitra terpercaya dalam menyediakan properti untuk puluhan juta calon pembeli dan penyewa
        </Typography>
      </div>
      <div className="partnership__partner">
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
        <Image src="/logo/180.png" width={164} height={90} />
      </div>
    </div>
  );
};

export default Partnership;
