import { N50 } from '@/styles/color';
import { css } from '@emotion/css';

export const partnershipContainerCx = css`
  display: flex;
  width: 100%;
  // overflow: scroll;
  column-gap: 24px;
  padding: 80px 0;
  border-radius: 16px;
`;

export const buttonContainerCx = css`
  display: flex;
  flex: 0 0 256px;
  align-items: center;
`;

export const buttonCx = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: ${N50};
  border-radius: 24px;
  width: 80px;
  height: 80px;
  margin-right: 16px;
`;
